<template>
  <v-container>
    <v-container grid-list-md fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xd="12" sm="9" md="8" lg="6" xl="4">

          <v-layout row wrap>
            <v-flex xs12 sm8>
              <v-form @submit.prevent="registrar" ref="form" autocomplete="off">
                <v-card class="elevation-12">
                  <v-toolbar color="primary" dark flat>
                    <v-icon large left>mdi-check-circle-outline</v-icon>
                    <v-spacer />
                    <v-toolbar-title class="title">Confirmar Registro de Ponto</v-toolbar-title>
                    <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                      <v-text-field label="Funcionário" name="funcionario" type="text" v-model="registro.funcionarioNome" :readonly="true"/>
                      <v-text-field label="Data/Hora" name="data" type="text" v-model="registro.now" :readonly="true"/>
                      <v-text-field label="Tipo" name="tipo" type="text" v-model="tipo" :readonly="true"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn type="submit" :disabled="livre" color="primary">
                        <v-icon dark left>mdi-check-circle</v-icon>Registrar
                      </v-btn>
                      <v-btn color="error" @click="voltar">
                        <v-icon dark left>mdi-arrow-left</v-icon>Voltar
                      </v-btn>
                    </v-card-actions>
                </v-card>
              </v-form>
            </v-flex>

            <v-flex xs12 sm4>
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-icon large left>mdi-clock-outline</v-icon>
                  <v-toolbar-title>{{ registro.datareg ? registro.datareg : "" }}</v-toolbar-title>
                  <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                    <p class="black--text"><span class="font-weight-bold">Ent. 01:</span> {{ registro.ent1 ? registro.ent1.substr(11) : "" }}</p>
                    <p class="black--text"><span class="font-weight-bold">Sai. 01:</span> {{ registro.sai1 ? registro.sai1.substr(11) : "" }}</p>

                    <p class="black--text"><span class="font-weight-bold">Ent. 02:</span> {{ registro.ent2 ? registro.ent2.substr(11) : "" }}</p>
                    <p class="black--text"><span class="font-weight-bold">Sai. 02:</span> {{ registro.sai2 ? registro.sai2.substr(11) : "" }}</p>

                    <p class="black--text"><span class="font-weight-bold">Ent. 03:</span> {{ registro.ent3 ? registro.ent3.substr(11) : "" }}</p>
                    <p class="black--text"><span class="font-weight-bold">Sai. 03:</span> {{ registro.sai3 ? registro.sai3.substr(11) : "" }}</p>
                  </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

        </v-col>
      </v-row>
    </v-container>


  </v-container>
</template>

<script>
export default {
  name: "pontoconfirmar",
  data() {
    return {
    };
  },

  computed: {
    registro(){
      return this.$store.state.ponto.registro;
    },
    livre(){
      return this.$store.state.ponto.proxPos == 33;
    },
    tipo(){
      if (!this.$store.state.ponto.proxPos == 33)
        return "";
      switch (this.$store.state.ponto.registro.proxPos) {
      case 11:
        return "Entrada 01";
      case 12:
        return "Saída 01";
      case 21:
        return "Entrada 02";
      case 22:
        return "Saída 02";
      case 31:
        return "Entrada 03";
      case 32:
        return "Saída 03";
      default:
        return "-";
      }
    }
  },

  mounted() {
    if (!this.registro.funcionarioNome){
      this.$router.push("/");
      return;
    }
  },

  methods: {
    registrar(){
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch("ponto/registrar").then(() => {
        this.$router.push("/");
      });
    },
    voltar(){
      this.$router.go(-1);
    },
  },

};
</script>